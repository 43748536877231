<template>
  <div class="row mx-0 mb-1">
    <div class="col-12 p-0">
      <div :for="id" class="form-label text-center" v-if="label" v-html="label"></div>
      <vue-google-autocomplete
        classname="form-control medium"
        :class="{small: size === 'small', big: size === 'big', gold: color === 'gold', white: color === 'white', 'outline-green': color === 'outline-green', error: hasErrors > 0}"
        :id="id ? id : 'map'"
        :placeholder="placeholder"
        v-on:placechanged="getAddressData"
        :disabled="disabled"
        :autocomplete="autocomplete"
        v-model="value"
      >
      </vue-google-autocomplete>
      <div class="isError text-center" v-for="(error, index) of errors" :key="index" >
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  name: 'MapAutocomplete',
  components: {
    VueGoogleAutocomplete
  },
  props: {
    id: String,
    placeholder: String,
    label: String,
    size: String,
    color: String,
    errors: Array,
    modelValue: String,
    hasErrors: Number,
    autocomplete: String,
    disabled: Boolean
  },
  emits: ['update:modelValue', 'inputChanged'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    getAddressData (addressData, placeResultData, id) {
      const address = {
        street_number: addressData.street_number,
        route: addressData.route,
        sublocality_level_1: addressData.sublocality_level_1,
        locality: addressData.locality,
        province: addressData.administrative_area_level_1,
        postal_code: addressData.postal_code,
        country: addressData.country,
        postal_town: addressData.administrative_area_level_2
      }
      this.$emit('inputChanged', address)
    },
    getAddressDetailByType (addressComponents, type) {
      const component = addressComponents.find(item => item.types.includes(type))
      if (!component) return ''
      return (type !== 'country' ? component.long_name : component.short_name)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 2rem;
    border: 2px solid transparent;
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0 1rem;
    font-weight: 500;
    text-align: left;
  }
  .form-control.gold {
    color: var(--gold-color);
  }
  .form-control.white {
    color: #fff;
  }
  .form-control.outline-green {
    border-color: var(--green-color);
    color: var(--normal-color);
    background-color: transparent;
  }
  .form-control:focus {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    box-shadow: none;
    border: 2px solid var(--green-color-light);
  }
  .form-control.gold:focus {
    color: var(--gold-color);
  }
  .form-control.white:focus {
    color: #fff;
  }
  .form-control.outline-green:focus {
    border-color: var(--green-color);
    color: var(--normal-color);
    background-color: transparent;
  }
  .big {
    height: 4.5rem;
    border-radius: 4rem;
    font-size: 2.5rem;
    text-align: center;
    color: var(--gold-color);
  }
  .form-control.big:focus {
    color: var(--gold-color);
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-color-light) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color-light) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color-light) !important;
  }
  .big::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gold-color) !important;
    opacity: 1; /* Firefox */
  }
  .big:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--gold-color) !important;
  }
  .big::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--gold-color) !important;
  }
  .uppercase {
    text-transform: capitalize;
  }
</style>
